.toggleBlock::before {
    content: ' ';
    display: inline-block;
  
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-left: 5px solid currentColor;
    vertical-align: middle;
    margin-right: .7rem;
    transform: translateY(-2px);
  
    transition: transform .2s ease-out;
    transform: rotate(90deg) translateX(-3px);
  }
  
.collapsed::before {
    transform: rotate(0deg) translateX(-3px);
  }
 