
.block{
  
    padding-left:8px;
    padding-right:8px;
    margin-left:4px;
    margin-right:4px;
    
    border-left: 2px solid silver;
    margin-bottom: 4px;
}
.block-table td{
  padding : 4px;
}
.block-header{
  color:black;
  cursor: pointer;
  margin-bottom: 3px;
  min-height: 50px;
  border-top:1px solid #d2d2d2;
}
.page-menu{

  padding-left:8px;
  padding-right:8px;
  padding-top:5px;
  margin-left:4px;
  margin-right:12px;
  margin-bottom: 8px;
  margin-top: 12px;
}
.block-menu{
    margin:8px;
    
    text-align: center;
    border-top: 3px solid black;
    background-color:white;
    box-shadow: 2px 2px 5px 2px silver;
}
.block-add-box{
    margin:4px;
   display: inline-block;
   vertical-align: middle;
    width:90px;
    height: 80px;
    text-align: center;
    font-size: 11px;
    cursor: pointer;
    color:gray;
}
.block-add-box:hover{
  color:black;
}
.block-add-box img{
  width:40px;
  height:40px;
    
}

.block-container{
  width:100%;
  height: 100%;
  display: flex;
}
.block-settings{
  width:400px;
  height: 100%;
  overflow-y: auto;
  background-color: #dcdcdc;
}

.block-content{
  padding:8px;
  height: 100%;
  width:100%;
  overflow-y: auto;
}

.htmlbuilder-paragraph-card{
    text-align: center;
    background-color: #f3f3f3;
    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
    border-radius: 4px 4px 4px 4px;
    padding:4px;
    margin:8px;
    width:312px;
  }
  
  .htmlbuilder-paragraph-card-image{
    margin:6px;
    width:300px;
    cursor:pointer;
  } 

  .htmlbuilder-paragraph-card-description{
    font-style: italic;
    color:#5a5a5a;
  }
  .htmlbuilder-gallery-container{
    display:flex;
    flex-wrap: wrap;
  }
  .htmlbuilder-gallery-card{
    
    text-align: center;
    background-color: #f3f3f3;
    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
    border-radius: 4px 4px 4px 4px;
    padding:4px;
    margin:4px;
    width:100%;
  }
  .htmlbuilder-gallery-card-image{
    margin:8px;
    width:90%;
  }
  .htmlbuilder-gallery-card-description{
    font-style: italic;
    color:#5a5a5a;
  }