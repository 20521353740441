body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*{ 
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif;

}
[contenteditable] {
  outline: 0px solid transparent;
}
th{
  text-align: left;
  font-size: 15px;
}
td{
  font-size: 15px;
}
ol,ul{
  padding-left: 40px ;
}
.indexlink{
  text-decoration: underline;
  cursor: pointer;
  padding-top: 5px;
  
}

  .checkbox span{
    font-size:12px;
  }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.highlightColorYellow{
  background-color: #ff0;
}
.errorline{
  font-style: italic;
  padding:4px;
  font-size:10px;
  color:red;
}
.required{
  color:red;
  padding-left: 8px;
}
.content{
  line-height: 1.5;
  box-sizing: border-box;
}
.questionline{
  font-weight: 600;
}
.avatar {
  vertical-align: middle;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-left: 14px;
  background-color: white;
}

.imagebox{
  margin:8px;
  border:1px solid silver;
}
@media only screen and (max-width: 600px) {
.imagebox{
  width:100% !important;
  float:none !important;
}
}
.imagebox img {
  width:100%;
}
.imagebox video {
  width:100%;
}
.paragraphimage{
  padding:8px;
  box-sizing: border-box;
  cursor:pointer;
}
.paragraphvideo{
  padding:8px;
  box-sizing: border-box;
  cursor:pointer;
}
.imageboxtext{
  font-size: 12px;
 padding:8px;
}
.veditorcheckbox{
  cursor: pointer;
}

.veditortext{
  width:100%;
  border:0px;
  min-height: 25px;
  height: 25px;
  border-bottom:1px solid #aa0009;
  font-size: 13px;
}

.veditor table{
    border-spacing:0px;
}

.veditor td{
    border: 1px solid #e4e4e4;
    padding:10px;
    font-size: 13px !important;
}

.veditor td p {
    font-size: 13px  !important;
}

.veditortextarea{
  width:100%;
  resize: none;
  overflow: hidden;
  min-height: 25px;
  height: 25px;
  border:0px;
  border-bottom:1px solid #aa0009;
  font-size: 13px;
}

.veditorselect{
  height: 30px;
  width: 100%;
  padding: 5px;
  border: none;
  border-bottom: 1px solid #aa0009;
  background-color:white;
  margin: 0px;
}
  